import { Suspense } from 'react';
import { createBrowserRouter, createRoutesFromElements, RouterProvider, Route, Outlet } from 'react-router-dom';

import { links } from 'libs/data-config';
import { Layout } from 'libs/layout';
import { Loader } from 'libs/mui';
import { PageMain, PageSbpSuccess, PageSuccess, PageFailed } from './router-pages';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={
        <Layout>
          <Outlet />
        </Layout>
      }
    >
      <Route path={links.success} element={<PageSuccess />} />
      <Route path={links.failed} element={<PageFailed />} />
      <Route path={links.sbpsuccess} element={<PageSbpSuccess />} />
      <Route path={links.main} element={<PageMain />} />
      <Route path={'*'} element={<PageMain />} />
    </Route>
  )
);

export const Router = () => {
  // console.log('🚀 ~ Router');

  return (
    <Suspense fallback={<Loader />}>
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default Router;
