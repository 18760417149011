/**
 * Form range field adapter component
 *
 * @overview
 */
import React from 'react';
import { useField, useFormikContext, FormikProps } from 'formik';

import { RangeSelector } from 'libs/mui';
import { Field } from '../../index';

export const RangeField = (props: any) => {
  const { min, max, ...other } = props;
  const { values, setFieldValue }: FormikProps<any> = useFormikContext();

  const [field, meta] = useField(other);

  const [value, setValue] = React.useState<number>(values[props.name]);

  const handleChange = (value: any) => {
    setValue(value);
  };

  const handleChangeText = (event: any) => {
    const newValue = parseInt(event.target.value);
    if (newValue >= min && newValue <= max) {
      setValue(newValue);
    }
  };

  React.useEffect(() => {
    setFieldValue(props.name, value);
  }, [value, setFieldValue, props.name]);

  return (
    <>
      <RangeSelector className="range-selector" value={value} min={min} max={max} onChanged={handleChange} />
      <Field {...props} {...field} className="range-field" onChange={handleChangeText} min={min} max={max} />
      {!!meta.touched && !!meta.error && <div>{meta.error}</div>}
    </>
  );
};

export default RangeField;
